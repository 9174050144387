<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4"> Untitled - {{ today }} </span>
          </v-col>
          <v-col cols="12" md="6" sm="6" class="text-right">
            <v-row class="d-flex justify-end">
              <v-col cols="12" md="4" sm="4" class="mt-n1 text-right">
                <v-select
                  v-model="status"
                  outlined
                  :items="statusItems"
                  item-value="name"
                  background-color="white"
                  style="border-radius: 0px"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      v-if="item.name == 'เปิดใช้งาน'"
                      style="color: #11c872"
                    >
                      <v-icon style="color: #11c872"> mdi-circle </v-icon>
                      <v-icon style="color: #11c872"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                      <v-icon style="color: red"> mdi-circle </v-icon>
                      <v-icon style="color: red"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                      <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                      <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span
                      v-if="item.name == 'เปิดใช้งาน'"
                      style="color: #11c872"
                    >
                      <v-icon style="color: #11c872"> mdi-circle </v-icon>
                      <v-icon style="color: #11c872"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                      <v-icon style="color: red"> mdi-circle </v-icon>
                      <v-icon style="color: red"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                      <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                      <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-divider class="mt-2" vertical style="height: 56px"></v-divider>
              <v-col cols="12" md="3" sm="3" class="text-right">
                <v-btn
                  @click="saveDraft('q_1_1')"
                  text
                  color="#2ab3a3"
                  style="
                    border: solid 1px #2ab3a3;
                    border-radius: 0px;
                    padding: 12px 16px;
                    gap: 8px;
                    width: 114px;
                    height: 48px;
                  "
                  >บันทึกฉบับร่าง</v-btn
                >
              </v-col>
              <v-col cols="12" md="3" sm="3" class="text-right">
                <v-btn
                  @click="submit('q_1_1')"
                  color="#2AB3A3"
                  dark
                  elevation="0"
                  style="
                    border-radius: 0px;
                    padding: 12px 16px;
                    gap: 8px;
                    width: 114px;
                    height: 48px;
                  "
                >
                  บันทึก
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-tabs centered grow v-model="e1">
      <v-tab
        class="text-h6"
        :style="
          e1 == 0
            ? 'border-bottom: 3px solid #2ab3a3'
            : 'border-bottom: 1px solid #2ab3a3'
        "
      >
        เนื้อหา
      </v-tab>
      <v-tab
        class="text-h6"
        :style="
          e1 == 1
            ? 'border-bottom: 3px solid #2ab3a3'
            : 'border-bottom: 1px solid #2ab3a3'
        "
      >
        SEO
      </v-tab>
      <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

      <v-tab-item class="pa-6">
        <!-- <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
          <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">
            เพิ่มข่าวและกิจกรรมคณะ
          </span>
        </v-col> -->
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <v-card class="pa-6" width="100%">
            <v-tabs v-model="e2">
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 0
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> ภาษาไทย </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 1
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> English </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="6"
                sm="6"
                style="border-bottom: 1px solid #2ab3a3"
              >
              </v-col>
              <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">หัวข้อ (TH)</span>
                        <span style="color: red">*</span>
                        <v-text-field
                          hide-details
                          v-model="form1.title_seo_th"
                          placeholder="ระบุหัวข้อ (TH)"
                          dense
                          outlined
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">เนื้อหา (TH)</span>
                        <span style="color: red">*</span>
                        <quill-editor
                          class="quill900"
                          :content="content"
                          :options="editorOption"
                          @change="onEditorChange($event)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">หัวข้อ (EN)</span>
                        <v-text-field
                          hide-details
                          v-model="form1.title_seo_en"
                          placeholder="ระบุหัวข้อ (EN)"
                          dense
                          outlined
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">เนื้อหา (EN)</span>
                        <quill-editor
                          class="quill900"
                          :content="content1"
                          :options="editorOption"
                          @change="onEditorChange1($event)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <!-- <v-col class="mt-5">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">แกลลอรี่</span>
      </v-col> -->

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                แกลลอรี่
              </span>
            </v-col>
          </v-row>

          <v-card class="pa-6" width="100%">
            <v-row>
              <v-col
                v-for="(item, indexItemPic) in this.showImageUpload"
                :key="indexItemPic"
                class="d-flex child-flex"
                cols="4"
              >
                <v-img
                  :src="item.path"
                  contain
                  max-width="400px"
                  min-height="400px"
                >
                  <v-row>
                    <v-col>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        x-small
                        style="
                          border: solid 1px;
                          border-radius: 0px;
                          opacity: 0.4;
                        "
                        @click="pictureDelete(indexItemPic)"
                        >X</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'grid'"
                  :multiple="true"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*'"
                  :helpText="'Choose images files or drag and drop here'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn
                  :disabled="!fileRecordsForUpload.length"
                  @click="uploadFiles()"
                  color="#2AB3A3"
                  dark
                  x-large
                >
                  Upload {{ fileRecordsForUpload.length }} files
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-6 mt-10" width="100%">
            <v-row>
              <v-col cols="12" md="12" sm="12" class="mt-5"
                ><span class="pt-2"><b>อัพโหลดไฟล์</b></span>
                <span style="color: red">*</span>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="text-left"
                  v-for="(item, indexItemDocs) in this.documentFilesNews"
                  :key="indexItemDocs"
                >
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <p
                        style="color: #4caf50; cursor: pointer"
                        @click="onClickFile(item.path)"
                      >
                        {{ item.name }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="9" sm="9" class="mt-n3">
                      <v-btn
                        color="red"
                        icon
                        @click="deleteFilesDocs(indexItemDocs)"
                      >
                        <v-icon>mdi-alpha-x-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <input
                    type="file"
                    accept="application/pdf,"
                    id="file"
                    ref="attachFile1"
                    v-on:change="submitFile('attachFile1')"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-card>

          <!-- <v-card class="pa-6" width="100%">
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="12"
                  md="11"
                  sm="11"
                  xs="11"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปข่าว</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">อัพโหลดรูปภาพขนาด 500x250px</span>
                  <br />
                  <v-img :src="showImage" max-width="auto"></v-img>
  
                  <v-col cols="12" md="12" class="text-right">
                    <input
                      type="file"
                      accept="image/*"
                      id="file"
                      ref="q_1_1"
                      v-on:change="submitfileMain('q_1_1')"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card> -->

          <!-- <v-row align="center" justify="end" class="pa-6">
        <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
        <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn>
      </v-row> -->
        </v-form>
      </v-tab-item>

      <v-tab-item class="pa-6">
        <v-form
          ref="createForm1"
          v-model="checkValidate1"
          :lazy-validation="lazy"
        >
          <v-card class="pa-6" width="100%">
            <v-tabs v-model="e2">
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 0
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> ภาษาไทย </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 1
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> English </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="6"
                sm="6"
                style="border-bottom: 1px solid #2ab3a3"
              >
              </v-col>
              <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-row>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">Keyword หลัก</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.keyword_seo_th"
                              placeholder="ระบุ Keyword หลัก 1 คำที่ต้องการ SEO"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อหัวข้อ (TH)</span>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.title_seo_th"
                              placeholder="ระบุชื่อหัวข้อ (TH)"
                              dense
                              outlined
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อลิ้งค์ URL</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.url_seo_th"
                              placeholder="ระบุชื่อลิ้งค์ URL"
                              dense
                              outlined
                              :rules="rules.required"
                              counter="75"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">คำอธิบายย่อ (TH)</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-textarea
                              v-model="form1.desc_seo_th"
                              placeholder="ระบุคำอธิบายย่อ (TH)"
                              dense
                              outlined
                              :rules="rules.required"
                              counter="150"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-card
                          color="#F5F5F5"
                          class="pa-6"
                          width="456px"
                          height="520px"
                        >
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <span class="text-h6"> SEO Checker </span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check1 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check1 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              กำหนด Keyword หลัก
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check2 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check2 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในหัวข้อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check3 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check3 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในลิ้งค์ URL
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check4 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check4 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในคำอธิบายย่อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check5 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check5 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ใน 10% แรกข้องเนื้อหา
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check6 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check6 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก ผสมอยู่ในเนื้อหาไม่ต่ำกว่า 5-10
                              ครั้ง
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check7 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check7 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              เนื้อหามีการลิ้งค์ไปยังหน้าอื่นๆ ในเว็บไซต์นี้
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
                      <span
                        style="
                          font-family: 'IBM Plex Sans Thai';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 24px;
                          line-height: 40px;
                        "
                      >
                        รูปปก
                      </span>
                    </v-col>
                    <v-card class="pa-6" width="100%">
                      <v-row>
                        <v-col cols="12" md="12" sm="12" xs="12">
                          <v-card outlined>
                            <v-row no-gutters align="center">
                              <v-col
                                cols="12"
                                md="11"
                                sm="11"
                                xs="11"
                                class="ma-4"
                                id="step-3"
                              >
                                <span>รูปข่าว</span>
                                <span style="color: red">*</span>
                                <br />
                                <span style="color: red"
                                  >ขนาดรูปที่แนะนำ : 2000 x 1050px</span
                                >
                                <br />
                                <v-img
                                  :src="showImage"
                                  max-width="auto"
                                ></v-img>

                                <v-col cols="12" md="12" class="text-right">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="file"
                                    ref="q_1_1"
                                    v-on:change="submitfileMain('q_1_1')"
                                  />
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-row>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">Keyword หลัก</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.keyword_seo_en"
                              placeholder="ระบุ Keyword หลัก 1 คำที่ต้องการ SEO"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อหัวข้อ (EN)</span>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.title_seo_en"
                              placeholder="ระบุชื่อหัวข้อ (EN)"
                              dense
                              outlined
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อลิ้งค์ URL</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.url_seo_en"
                              placeholder="ระบุชื่อลิ้งค์ URL"
                              dense
                              outlined
                              :rules="rules.required"
                              counter="75"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">คำอธิบายย่อ (EN)</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-textarea
                              v-model="form1.desc_seo_en"
                              placeholder="ระบุคำอธิบายย่อ (EN)"
                              dense
                              outlined
                              :rules="rules.required"
                              counter="150"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-card
                          color="#F5F5F5"
                          class="pa-6"
                          width="456px"
                          height="520px"
                        >
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <span class="text-h6"> SEO Checker </span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check8 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check8 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              กำหนด Keyword หลัก
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check9 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check9 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในหัวข้อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check10 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check10 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในลิ้งค์ URL
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check11 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check11 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในคำอธิบายย่อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check12 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check12 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ใน 10% แรกข้องเนื้อหา
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check13 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check13 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก ผสมอยู่ในเนื้อหาไม่ต่ำกว่า 5-10
                              ครั้ง
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon
                                color="red"
                                class="mt-n2"
                                v-if="check14 == false"
                                >mdi-close-circle-outline</v-icon
                              >
                              <v-icon
                                color="green"
                                class="mt-n2"
                                v-if="check14 == true"
                                >mdi-check-circle-outline</v-icon
                              >
                              เนื้อหามีการลิ้งค์ไปยังหน้าอื่นๆ ในเว็บไซต์นี้
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
                      <span
                        style="
                          font-family: 'IBM Plex Sans Thai';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 24px;
                          line-height: 40px;
                        "
                      >
                        รูปปก
                      </span>
                    </v-col>
                    <v-card class="pa-6" width="100%">
                      <v-row>
                        <v-col cols="12" md="12" sm="12" xs="12">
                          <v-card outlined>
                            <v-row no-gutters align="center">
                              <v-col
                                cols="12"
                                md="11"
                                sm="11"
                                xs="11"
                                class="ma-4"
                                id="step-3"
                              >
                                <span>รูปข่าว</span>
                                <span style="color: red">*</span>
                                <br />
                                <span style="color: red"
                                  >ขนาดรูปที่แนะนำ : 2000 x 1050px</span
                                >
                                <br />
                                <v-img
                                  :src="showImage"
                                  max-width="auto"
                                ></v-img>

                                <v-col cols="12" md="12" class="text-right">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="file"
                                    ref="q_1_1"
                                    v-on:change="submitfileMain('q_1_1')"
                                  />
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <!-- <v-col class="mt-5">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">แกลลอรี่</span>
      </v-col> -->
        </v-form>
      </v-tab-item>
    </v-tabs>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      attachFile1: "",
      documentFilesNews: [],

      e1: 0,
      e2: 0,
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      check6: false,
      check7: false,
      check8: false,
      check9: false,
      check10: false,
      check11: false,
      check12: false,
      check13: false,
      check14: false,
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
      itemsUploadFile: [],
      showImageUpload: [],
      count: 0,
      count1: 0,

      getToday: new Date().toLocaleString(),
      today: "",
      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],
      form: {
        title_en: "",
        title_th: "",
        short_desc_en: "",
        short_desc_th: "",
        full_desc_en: "",
        full_desc_th: "",
        img_url: "",
        type: 4,
      },
      form1: {
        keyword_seo_th: "",
        keyword_seo_en: "",
        title_seo_th: "",
        title_seo_en: "",
        url_seo_th: "",
        url_seo_en: "",
        desc_seo_th: "",
        desc_seo_en: "",
      },
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      checkValidate1: true,
      lazy: false,
      content: "",
      content1: "",
      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    // console.log("this is current quill instance object", this.editor);
    this.setDate();
  },
  watch: {
    "form1.keyword_seo_th"(val) {
      if (val) {
        this.check1 = true;
        this.count1 = 0;
        this.checkTenPercent();
        this.checkDuplicateWordsThai();
        if (this.form1.title_seo_th.includes(val)) {
          this.check2 = true;
        } else {
          this.check2 = false;
        }

        if (this.form1.url_seo_th.includes(val)) {
          this.check3 = true;
        } else {
          this.check3 = false;
        }

        if (this.form1.desc_seo_th.includes(val)) {
          this.check4 = true;
        } else {
          this.check4 = false;
        }
      } else {
        this.check1 = false;
        this.check2 = false;
        this.check3 = false;
        this.check4 = false;
        this.check5 = false;
        this.check6 = false;
        this.check7 = false;
      }
    },
    "form1.keyword_seo_en"(val) {
      if (val) {
        this.check8 = true;
        this.count = 0;
        this.checkTenPercent();
        this.checkDuplicateWordsEnglish();
        if (this.form1.title_seo_en.includes(val)) {
          this.check9 = true;
        } else {
          this.check9 = false;
        }

        if (this.form1.url_seo_en.includes(val)) {
          this.check10 = true;
        } else {
          this.check10 = false;
        }

        if (this.form1.desc_seo_en.includes(val)) {
          this.check11 = true;
        } else {
          this.check11 = false;
        }
      } else {
        this.check8 = false;
        this.check9 = false;
        this.check10 = false;
        this.check11 = false;
        this.check12 = false;
        this.check13 = false;
        this.check14 = false;
      }
    },
    "form1.title_seo_th"(val) {
      if (this.form1.keyword_seo_th) {
        if (val.includes(this.form1.keyword_seo_th)) {
          this.check2 = true;
        } else {
          this.check2 = false;
        }
      } else {
        this.check2 = false;
      }
    },
    "form1.title_seo_en"(val) {
      if (this.form1.keyword_seo_en) {
        if (val.includes(this.form1.keyword_seo_en)) {
          this.check9 = true;
        } else {
          this.check9 = false;
        }
      } else {
        this.check9 = false;
      }
    },
    "form1.url_seo_th"(val) {
      if (val.includes(this.form1.keyword_seo_th)) {
        this.check3 = true;
      } else {
        this.check3 = false;
      }
    },
    "form1.url_seo_en"(val) {
      if (val.includes(this.form1.keyword_seo_en)) {
        this.check10 = true;
      } else {
        this.check10 = false;
      }
    },
    "form1.desc_seo_th"(val) {
      if (val) {
        if (val.includes(this.form1.keyword_seo_th)) {
          this.check4 = true;
        } else {
          this.check4 = false;
        }
      }
    },
    "form1.desc_seo_en"(val) {
      if (val) {
        if (val.includes(this.form1.keyword_seo_en)) {
          this.check11 = true;
        } else {
          this.check11 = false;
        }
      }
    },
    "form.full_desc_en"(val) {
      if (val) {
        this.count = 0;
        this.checkDuplicateWordsEnglish();
        this.checkTenPercent();
      }
    },
    "form.full_desc_th"(val) {
      if (val) {
        this.count = 0;
        this.checkDuplicateWordsThai();
        this.checkTenPercent();
      }
    },

    content(val) {
      if (val.includes("gscm.nida.ac.th")) {
        this.check7 = true;
      } else {
        this.check7 = false;
      }
    },
    content1(val) {
      if (val.includes("gscm.nida.ac.th")) {
        this.check14 = true;
      } else {
        this.check14 = false;
      }
    },
  },
  methods: {
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "attachFile1") {
            // this.attachFile1 = response.data.data.path;
            this.documentFilesNews.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }
          console.log("this.documentFilesNews", this.documentFilesNews);
          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },
    deleteFilesDocs(val) {
      this.documentFilesNews.splice(val, 1);
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    checkDuplicateWordsEnglish() {
      const keywordEng = this.form1.keyword_seo_en;
      const words = this.form.full_desc_en.replace(/\n/g, " ");
      const findDuplicateWords = (words) => {
        const strArr = words.split(" ");
        const result = [];
        console.log(strArr);
        for (let i = 0; i < strArr.length; i++) {
          if (strArr[i] == keywordEng) {
            result.push(strArr[i]);
            this.count++;
          }
        }
        return result.join(" ");
      };
      console.log(findDuplicateWords(words));
      console.log(this.count, "this.count");
      if (this.count >= 5) {
        this.check13 = true;
      } else {
        this.check13 = false;
      }
    },
    checkDuplicateWordsThai() {
      const keywordTh = this.form1.keyword_seo_th;
      const words = this.form.full_desc_th.replace(/\n/g, "");
      console.log("words", words);
      let re = new RegExp(keywordTh, "g");
      let duplicateCount = words.match(re);
      console.log("duplicateCount", duplicateCount);
      if (this.form1.keyword_seo_th) {
        if (duplicateCount) {
          if (duplicateCount.length >= 5) {
            this.check6 = true;
          } else {
            this.check6 = false;
          }
        } else {
          this.check6 = false;
        }
      }
    },
    checkTenPercent() {
      console.log("1");
      let constVal = this.form.full_desc_th.length - 1;
      let constValEng = this.form.full_desc_en.length - 1;
      console.log(constVal, "constVal");
      console.log(constValEng, "constValEng");
      let tenpercent = constVal * 0.1;
      let tenpercentEn = constValEng * 0.1;
      console.log("tenpercent", tenpercent);
      console.log("tenpercentEn", tenpercentEn);

      let first10 = this.form.full_desc_th.slice(0, tenpercent);
      let first11 = this.form.full_desc_en.slice(0, tenpercentEn);
      console.log("first10", first10);
      console.log("first11", first11);
      if (first10.includes(this.form1.keyword_seo_th)) {
        if (this.form1.keyword_seo_th) {
          this.check5 = true;
        } else {
          this.check5 = false;
        }
      } else {
        this.check5 = false;
      }

      if (first11.includes(this.form1.keyword_seo_en)) {
        if (this.form1.keyword_seo_en) {
          this.check12 = true;
        } else {
          this.check12 = false;
        }
      } else {
        this.check12 = false;
      }
    },
    setDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      this.today = date;
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onEditorChange({ quill, html, text }) {
      this.form.full_desc_th = text;
      // console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      this.form.full_desc_en = text;
      // console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageNews3");
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.form.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        // await this.submitfileMain(pic);
        console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          pathPic: this.showImageUpload,
          status: this.status,
          title_th: this.form1.title_seo_th,
          title_en: this.form1.title_seo_en,
          short_desc_th: this.form.short_desc_th,
          short_desc_en: this.form.short_desc_en,
          full_desc_th: this.form.full_desc_th,
          full_desc_en: this.form.full_desc_en,
          type: this.form.type,
          img_url: this.form.img_url,
          documentFilesNews: this.documentFilesNews,
          /////
          keyword_seo_th: this.form1.keyword_seo_th,
          keyword_seo_en: this.form1.keyword_seo_en,
          url_seo_th: this.form1.url_seo_th,
          url_seo_en: this.form1.url_seo_en,
          desc_seo_th: this.form1.desc_seo_th,
          desc_seo_en: this.form1.desc_seo_en,
          // bannerUrl: this.bannerUrl,
        };
        console.log("data111", data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/news`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageNews3");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async saveDraft(pic) {
      this.status = "ฉบับร่าง";

      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        pathPic: this.showImageUpload,
        status: this.status,
        title_th: this.form1.title_seo_th,
        title_en: this.form1.title_seo_en,
        short_desc_th: this.form.short_desc_th,
        short_desc_en: this.form.short_desc_en,
        full_desc_th: this.form.full_desc_th,
        full_desc_en: this.form.full_desc_en,
        type: this.form.type,
        img_url: this.form.img_url,
        documentFilesNews: this.documentFilesNews,
        /////
        keyword_seo_th: this.form1.keyword_seo_th,
        keyword_seo_en: this.form1.keyword_seo_en,
        url_seo_th: this.form1.url_seo_th,
        url_seo_en: this.form1.url_seo_en,
        desc_seo_th: this.form1.desc_seo_th,
        desc_seo_en: this.form1.desc_seo_en,
      };
      console.log("data111", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/news`,
        data,
        auth
      );
      console.log("createBanner", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `บันทึกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("ManageNews3");
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async pictureDelete(val) {
      this.showImageUpload.splice(val, 1);
    },
    async getUploadFile() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/atmospheres?courseId=${this.courseId}`,
        auth
      );
      console.log("getUploadFile", response.data.data);
      this.itemsUploadFile = response.data.data;
      this.showImageUpload = response.data.data[0].pathPic;
      console.log("showImageUpload", this.showImageUpload);
      this.loading = false;
    },
    async uploadFiles() {
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let pathPic = [];
      pathPic = this.showImageUpload;

      for (let i = 0; i < this.fileRecords.length; i++) {
        let formData = new FormData();
        formData.append("files", this.fileRecords[i].file);
        formData.append("filename", this.fileRecords[i].file.name);
        formData.append("type", "uploadfile");

        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        pathPic.push({ path: response.data.data.path });
        console.log("pathPic", pathPic);
        console.log("fileRecords", this.fileRecords);
      }
      this.uploadFiles1(pathPic);
      this.fileRecords = [];
      this.fileRecordsForUpload = [];
    },
    async uploadFiles1(pathPic) {
      // console.log("itemsUploadFile", this.itemsUploadFile[0].id);
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        courseId: this.courseId,
        pathPic: pathPic,
        fileRecords: this.fileRecords,
      };
      if (this.itemsUploadFile.length) {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/atmospheres/${this.itemsUploadFile[0].id}`,
          data,
          auth
        );
        console.log("uploadPathPic", response);
        console.log(response.data.response_status);
      } else {
      }
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

